import React, { useState } from 'react'
import { StyleSheet, Text, View, Platform, Image, TouchableOpacity, ScrollView, } from 'react-native'
import HeaderForTermAndCondition from '../components/HeaderForTermAndCondition'
import { COLORS, isWeb, isMobile, taxiLinkFontSize } from '../utils'
import { images } from '../assets'
import { Picker } from '@react-native-picker/picker';
import { fontFamilies } from "../assets/fonts";
export default function TermAndConditions() {

    const array = [
        {
            id: 1,
            text: 'Lorem Ipsum is simply  ',
            description:`Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum`
        },
        {
            id: 2,
            text: 'Where does it come from?',
            description:`There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc`

        },
        {
            id: 3,
            text: 'General Terms for Business Travelers Business',
            description:`hese terms and conditions ("Terms") have been issued by Cabonline Group AB ("Cabonline"), a Swedish limited liability corporation with registration number 556552-1183 and registered address Box 16017, 103 21 Stockholm. In these the Terms "we", "our" or "us" refer to Taxilink and "you", "yours" and "your" refer to the person who enters into the agreement with Cabonline.

            The following Terms sets out the conditions for using our reservation service for taxi transportation as well as associated services, e.g. an overview of previous trips, pre-registered payment details or other services as from time to time made available (the "Services") through Cabonline’s mobile application, website or through our call centres (jointly referred to as "platforms").
            
            As these Terms govern your access or usage of Services made available by Cabonline and are part of your contract with Cabonline we kindly ask you to read them carefully and thoroughly. Cabonline may amend the Terms from time to time. Amendments will be effective upon Cabonline posting the updated Terms. Your continued access and usage of the Services after such posting constitutes your approval of the amendments.
            
            The Terms constitute a part of every individual booking or purchase made through Cabonline. By registering an account at Cabonline you hereby confirm that you are bound by the Terms. If you do not accept these Terms you are not allowed to access or use the Services.
            
            SCOPE OF SERVICES  Unless otherwise is agreed in a separate written agreement with us, we only offer a platform that makes it possible to reserve taxi transportation with taxi companies ("Suppliers"). The Suppliers can advertise their transportation services in the platform, and the users can use the platforms to make reservations with the Suppliers. By making a reservation through us you enter into a legally binding agreement regarding transportations with the Suppliers. We then act as an intermediary between you and the Supplier, sending your reservation details to the Supplier and sending you the booking confirmation and other relevant information related to the booking. We also provide an overview of previous trips. By accepting these Terms, you hereby confirm that we do not provide any transportation services and that all taxi transportation services are provided by independent suppliers.
            
            The information disclosed in our platforms are based on data provided by the Suppliers. The Suppliers are responsible for providing updated data on rates, availability etc.
            `

        }
    ]


    const handleLanguageChange = (itemValue, itemIndex) => {
        const selectedItem = array[itemIndex];
        setSelectedLanguage(itemValue);
        setDescription(selectedItem.description);
      };

    const [selectedLanguage, setSelectedLanguage] = useState(array[0].text);
    const [description, setDescription] = useState(array[0].description);
    return (
        <View style={styles.container}>
            <View style={{ width: '100%', height: isWeb ? '38%' : '30%', backgroundColor: COLORS.lightGray2 }}>
                <HeaderForTermAndCondition />

                <View style={isWeb ? styles.webLogo : styles.mobLogo}>
                    <Image
                        source={images.logo1}
                        style={{
                            width: isWeb ? taxiLinkFontSize(18) : taxiLinkFontSize(16),
                            height: isWeb ? taxiLinkFontSize(9) : taxiLinkFontSize(7),
                            resizeMode: 'contain'
                        }}
                    />
                </View>

                <View style={styles.logoTextContainer}>
                    <Text style={{ color: 'white', fontFamily: fontFamilies.Popins, fontSize: isWeb ? taxiLinkFontSize(5.5) : taxiLinkFontSize(3.2), fontWeight: '800' }}>Terms of Service</Text>
                </View>

            </View>

            <View style={styles.textContainer}>
                <View style={isWeb ? styles.leftContent : styles.leftContentMobile}>
                    {
                        isWeb ?
                            <>
                                {
                                    array?.map((item) => {
                                        return (
                                            <TouchableOpacity onPress={() => {setDescription(item.description),setSelectedLanguage(item.text) }} style={styles.leftInnerView}>
                                                <Text style={styles.leftText}>{item.text}</Text>
                                            </TouchableOpacity>
                                        )
                                    })
                                }
                            </>

                            :
                            <Picker selectedValue={selectedLanguage}
                                style={styles.dropdownStyle}
                                
                                onValueChange={handleLanguageChange}>
                                <>
                                    {
                                        array?.map((item, index) => (
                                            <Picker.Item key={index} numberOfLines={1} label={item.text} value={item.text} />
                                        ))
                                    }
                                </>

                            </Picker>

                        // <Picker
                        //     selectedValue={selectedLanguage}
                        //     onValueChange={(itemValue, itemIndex) =>
                        //         setSelectedLanguage(itemValue)
                        //     }>
                        //     <Picker.Item label="Java" value="java" />
                        //     <Picker.Item label="JavaScript" value="js" />
                        // </Picker>

                    }




                </View>
                <View style={styles.rightContent}>
                    <View style={{ width: '100%', height:isWeb? '20%':'15%', paddingLeft: '3%', justifyContent: 'center', marginTop: isWeb ? '2%' : null, alignItems: isMobile ? 'center' : null, }}>
                        <Text style={{ color: COLORS.secondary, fontFamily: fontFamilies.Inter, fontWeight: '400', fontSize: isWeb ? taxiLinkFontSize(3) : taxiLinkFontSize(2) }}>{selectedLanguage}</Text>
                    </View>
                    <View style={{ width: '100%', height: '70%',paddingHorizontal:isWeb?'4%':'4%',paddingBottom:10 }}>
                        <ScrollView showsVerticalScrollIndicator={false}>
                            <Text style={{textAlign:'justify',fontFamily:fontFamilies.Inter,fontSize:isWeb?taxiLinkFontSize(1.4):taxiLinkFontSize(1.1),color:COLORS.secondary}}>{description}</Text>
                        </ScrollView>
                    </View>
                </View>
            </View>

        </View >
    )
}

const styles = StyleSheet.create({
    container: {
        width: '100%',
        height: '100%',
        // backgroundColor: 'red',
    },
    webLogo: {
        width: '100%',
        // height: '50%',
        // justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',

    },
    mobLogo: {
        width: 128,
        // height: 57,
        // height: '50%',

        // marginTop: 20,
        // backgroundColor:'blue',

        alignSelf: 'center',
    },
    logoTextContainer: {
        width: '100%',
        height: '30%',
        justifyContent: 'center',
        alignItems: 'center',
        // backgroundColor: 'red',
        position: "absolute",
        bottom: 0
    },
    textContainer: {
        width: '100%',
        height: isWeb ? '62%' : '70%',
        backgroundColor: COLORS.navyBlue,
        flexDirection: isWeb ? 'row' : 'column'
    },
    leftContent: {
        width: '30%',
        height: '90%',
        alignItems: 'center',
        paddingTop: '3%',
        // backgroundColor: 'red'
    },
    rightContent: {
        width: isWeb ? '70%' : '100%',
        height: '100%',
        // backgroundColor: 'green'
    },
    leftInnerView: {
        width: '90%',
        height: 65,
        borderBottomColor: COLORS.secondary,
        marginTop: '3%',
        paddingHorizontal: 5,
        justifyContent: 'center',
        borderBottomWidth: .5,

    },
    leftText: {
        fontFamily: fontFamilies.Inter,
        fontSize: taxiLinkFontSize(1.8),
        fontWeight: '400',
        color: COLORS.secondary
    },
    leftContentMobile: {
        width: '100%',
        height: 56,
        padding: 10,
        backgroundColor: COLORS.secondary
    },
    dropdownStyle: {
        width: '100%', height: '100%', borderWidth: 0,
    }
})