import { firbaseInitailize } from '../../firebaseConfig';
import { getDatabase, ref, set, get, child, update, push, onValue ,remove} from "firebase/database";


export const writeData = (data, tableName) => {
  const db = getDatabase();
  const postListRef = ref(db, tableName);
  const newPostRef = push(postListRef);
  set(newPostRef, {
    data
  });

  console.log(JSON.stringify(data) + tableName + "Successfully Stored");
};

export const addData = (name) => {
  const db = getDatabase();
  const postListRef = ref(db, 'name');
  const newPostRef = push(postListRef);
  set(newPostRef, {
    name: name
  });
}



// export const fetchData = async () => {
//     var v;
//     const db = getDatabase();
//     const starCountRef = ref(db, 'booking');
//     onValue(starCountRef, (snapshot) => {
//       const data = snapshot.val();
//        v=Object.values(data);
//     //   console.log(`data====`,Object.values(data));
//     });
//     return v;
// };





// export const updateField = (tableName, documentId, fieldName, valueOfField) => {
export const updateField = (documentId,value) => {
  // const documentId = '-NjLngYWg3BUhIBgWZvV';
  console.log("ID--->",documentId);
  const db = getDatabase();
  const starCountRef = ref(db, 'booking/' + documentId); // Concatenate documentId to the reference
  
  // Update the specific field in the document
  update(starCountRef, {
    status: value,
    // Add more fields to update as needed
  })
    .then(() => {
      console.log('Document successfully updated!');
    })
    .catch((error) => {
      console.error('Error updating document: ', error);
    });
};

export const updateReadBit = (documentId,value) => {
  // const documentId = '-NjLngYWg3BUhIBgWZvV';
  console.log("ID--->",documentId);
  const db = getDatabase();
  const starCountRef = ref(db, 'booking/' + documentId); // Concatenate documentId to the reference
  
  // Update the specific field in the document
  update(starCountRef, {
    readBit: value,
    // Add more fields to update as needed
  })
    .then(() => {
      console.log('Document successfully updated!');
    })
    .catch((error) => {
      console.error('Error updating document: ', error);
    });
};



export const fetchId = () => {
  return new Promise((resolve, reject) => {
    const db = getDatabase();
    const starCountRef = ref(db, 'booking');

    onValue(starCountRef, (snapshot) => {
      const data = snapshot.val();
      const v = Object.keys(data);
      resolve(v);
    }, (error) => {
      reject(error);
    });
  });
};

export const fetchData = () => {
  return new Promise((resolve, reject) => {
    const db = getDatabase();
    const starCountRef = ref(db, 'booking');

    onValue(starCountRef, (snapshot) => {
      const data = snapshot.val();
      const v = Object.values(data);
      resolve(v);
    }, (error) => {
      reject(error);
    });
  });
};



export const deleteRide = (dataId) => {
  const db = getDatabase();
  const dataRef = ref(db, `booking/${dataId}`);

  // Remove the data at the specified path
  remove(dataRef)
    .then(() => {
      console.log(`Data with ID ${dataId} deleted successfully from booking.`);
      alert("Booking cancelled successfully")
    })
    .catch((error) => {
      console.error("Error deleting data:", error);
    });
};



export const listenForChanges = (tableName, onDataChange) => {
  const db = getDatabase();
  const dataRef = ref(db, tableName);

  // Set up a real-time listener
  const unsubscribe = onValue(dataRef, (snapshot) => {
    // The callback is triggered whenever the data changes
    if (snapshot.exists() && onDataChange && typeof onDataChange === "function") {
      // Call the provided onDataChange function with the updated data
      onDataChange(snapshot.val());
    }
  });

  // Return the unsubscribe function so that the caller can stop listening if needed
  return unsubscribe;
};