import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  ImageBackground,
  Image,
  TextInput,
  TouchableOpacity,
  ScrollView,
} from "react-native";
import { COLORS, SIZES, taxiLinkFontSize, isMobile, isWeb } from "../utils";
import { images } from "../assets";
import Checkbox from "expo-checkbox";
import CustomTextInput from "../components/CustomTextInput";
import {
  responsiveHeight,
  responsiveWidth,
} from "../utils/responsiveDimensions";
import CustomButton from "../components/CustomButton";
import CustomModal from "../components/CustomModal";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useIsFocused } from "@react-navigation/native";
import { webLogoBar } from "../assets/images";
import { fontFamilies } from "../assets/fonts";
import { mobileHeight } from "../utils/Sizes";
export default function Login({ navigation }) {
  const [email, setEmail] = useState("");
  const [isChecked, setChecked] = useState(false);
  const [password, setPassword] = useState("");

  const auth = getAuth();

  const isFocused = useIsFocused();
  useEffect(() => {
    getUser();
  }, [isFocused]);

  const getUser = async () => {
    try {
      const isLoggedIn = await AsyncStorage.getItem("isLoggedIn");
      console.log("isLoggedIn: ", isLoggedIn);
      if (isLoggedIn === "true") {
        navigation.navigate("HomeScreen");
      }
    } catch (error) {
      console.log("fetchingAsyncError:", error);
    }
  };

  console.log("===> ", auth, email, password, isChecked);

  const signIn = async () => {
    if (!email.trim()) {
      alert("Please enter email");
      return;
    } else if (!password.trim()) {
      alert("Please enter password");
      return;
    } else {
      try {
        let v = await signInWithEmailAndPassword(auth, email, password);
        if (v.operationType === "signIn") {
          if (isChecked) {
            await AsyncStorage.setItem("isLoggedIn", "true");
            navigation.navigate("HomeScreen");
          } else {
            navigation.navigate("HomeScreen");
          }
        }
        // try {
        //   await AsyncStorage.setItem('login', JSON.stringify('true'))
        //   navigation.navigate('HomeScreen');
        // } catch (error) {
        //   console.log(error);
        // }
      } catch (error) {
        alert(error);
        console.log("error -->>>", error);
      }
    }
  };

  return (
    <View style={styles.container}>
      {/* <ScrollView style={{ flex: 1 }}> */}
        <View style={isWeb ? styles.webMainBox : styles.mobileMainBox}>
          {/* <ImageBackground
            source={images.loginLeftBackground}
            style={{
              width: isMobile ? "100%" : responsiveWidth(30),
              height: isMobile ? responsiveHeight(40) : "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Image
              source={images.logo}
              style={{
                width: isMobile ? responsiveWidth(60) : responsiveWidth(18),
                height: isMobile ? responsiveWidth(26.5) : responsiveWidth(8),
              }}
            />
          </ImageBackground> */}
          {isWeb ? (
            <Image
              source={images.webLogoBar}
              style={{
                width: taxiLinkFontSize(32),
                height: "100%",
              }}
            />
          ) : (
            <View style={{width:'100%',height:'30%'}}>
              <Image
                source={images.mobLogoBar}
                style={{
                  width: "100%",
                  // height: taxiLinkFontSize(54.7),
                  height: '100%',
                  // resizeMode: 'contain'
                }}
              />
            </View>

          )}

          <View style={isWeb ? styles.rightWeb : styles.rightMobile}>
            <View
              style={isWeb ? styles.innerRightWeb : styles.innerRightMobile}
            >
              <View style={{ marginBottom:isMobile? mobileHeight<750? 10:20:null, alignItems: "center" }}>
                <Text style={styles.headingtxt}>{"Logga in på panelen"}</Text>
              </View>
              <CustomTextInput
                inputlable={"E-post"}
                onChangeText={setEmail}
                placeholder="Enter email here"
                placeholderTextColor={COLORS.darkgray}
                value={email}
              />
              <CustomTextInput
                inputlable={"Lösenord"}
                onChangeText={setPassword}
                placeholder="************"
                placeholderTextColor={COLORS.darkgray}
                value={password}
                secureTextEntry={true}
              />
              <View
                style={{
                  flexDirection: "row",
                  marginVertical: 30,
                }}
              >
                <Checkbox
                  style={styles.checkbox}
                  value={isChecked}
                  onValueChange={setChecked}
                  color={isChecked ? COLORS.primary : undefined}
                />
                <Text style={styles.checkboxtxt}>{"Håll mig inloggad"}</Text>
              </View>
              <CustomButton
                buttonStyle={{ marginTop: isWeb ? taxiLinkFontSize(5) : taxiLinkFontSize(2), marginBottom: isMobile && taxiLinkFontSize(10) }}
                buttonLabel={"Logga in"}
                onPress={() => signIn()}
              />
            </View>
          </View>
        </View>
      {/* </ScrollView> */}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: COLORS.black,
  },
  webMainBox: {
    width: "100%",
    flexDirection: "row",
    height: responsiveHeight(100),
    // flex: 1,
  },
  mobileMainBox: {
    width: "100%",
    flex: 1,
  },
  mobileLeft: {
    width: "100%",
    height: "30%",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FFCA29",
  },
  webLeft: {
    width: responsiveWidth(30),
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  webLeftogoImg: {
    width: 240,
    height: 106,
    resizeMode: "contain",
  },
  mobileLogoImg: {
    width: responsiveWidth(55),
    resizeMode: "contain",
  },
  backgroundImg: {
    width: "100%",
    height: "100%",
  },
  rightWeb: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: COLORS.black,
    marginTop: 50,
  },
  rightMobile: {
    width: "100%",
    height:'70%',
    backgroundColor: COLORS.black,
    marginTop: '10%',
  },
  innerRightMobile: {
    width: "75%",
    // flex: 1,
    // height:'80%',
    // marginTop: isMobile?mobileHeight>750? '10%':-15:null,
    alignSelf: "center",
  },
  innerRightWeb: {
    width: "40%",
    flex: 1,
    alignSelf: "center",
    justifyContent: "center",
  },
  headingtxt: {
    color: COLORS.white,
    fontFamily: fontFamilies.InterRegular,
    fontSize: isWeb ? taxiLinkFontSize(2.8) : taxiLinkFontSize(2.4),
    paddingRight: isWeb ? 10 : null,
    fontWeight: "500",
  },

  checkbox: {
    alignSelf: "center",
    backgroundColor: COLORS.white,
    borderRadius: 5,
    borderWidth: 0,
  },
  checkboxtxt: {
    fontFamily: fontFamilies.InterRegular,
    fontSize: isWeb ? taxiLinkFontSize(1.8) : taxiLinkFontSize(1.6),
    color: COLORS.white,
    alignSelf: "center",
    marginLeft: 10,
  },
  secondlogoStyle: {
    height: isMobile ? responsiveHeight(9) : responsiveHeight(18.8),
    width: isMobile ? responsiveWidth(17.8) : responsiveWidth(8.3),
  },
});
