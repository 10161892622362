import {
  StyleSheet,
  Text,
  View,
  Modal,
  Image,
  TouchableOpacity,
  ScrollView,
  FlatList,
} from "react-native";
import React from "react";
import {
  responsiveHeight,
  responsiveWidth,
  taxiLinkFontSize,
} from "../utils/responsiveDimensions";
import { fontFamilies } from "../assets/fonts";
import { COLORS, isMobile, isWeb } from "../utils";
import { images } from "../assets";
import moment from "moment";

const NotificationPopUp = (props) => {
  const {
    visible,
    firstText,
    firstPhone,
    secondPhone,
    secondTxt,
    thirdText,
    thirdPhone,
    forthtext,
    forthphone,
    onRequestClose,
    pendingBookings,
    nonPendingBookings,
    dataArray,
    pendingItemPress,
  } = props;

  function sortedData() {
    const tempArray = [
      ...dataArray.filter((item) => !item.readBit),
      ...dataArray.filter((item) => item.readBit),
    ];
    return tempArray;
  }

  const getRelativeDate = (dateString) => {
    const currentDate = new Date();
    const [day, month, year] = dateString.split("-");
    const inputDate = new Date(`${year}-${month}-${day}`);

    const difference = currentDate - inputDate;
    const daysDifference = Math.floor(difference / (1000 * 60 * 60 * 24));
    console.log(
      "dates ===>",
      dateString,
      difference,
      daysDifference
    );
    switch (daysDifference) {
      case 0:
        return "Today";
      case 1:
        return "Yesterday";
      default:
        if (daysDifference > 1) {
          return `${daysDifference} days ago`;
        } else {
          return "In the future";
        }
    }
  };

  const renderedItem = (item, index) => {
    console.log("asasa", item.data);
    if (!item.readBit) {
      return (
        <TouchableOpacity
          style={styles.secondView}
          onPress={() => pendingItemPress(item, index)}
        >
          <View
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            <View>
              <Text style={styles.secondViewTxt}>
                {item.name} {"bokade resan"}
              </Text>
              {/* <Text style={styles.secondViewTxtInner}>{item.date+" Se detaljer"}</Text> */}
              {/* <Text style={styles.secondViewTxtInner}>{moment(item.orderAt, "YYYY-MM-DDTHH:mm:ssZ").fromNow() +" Se detaljer"}</Text> */}
              <Text style={styles.secondViewTxtInner}>{moment(item.orderAt, "YYYY-MM-DD HH:mm:ss").fromNow() +" Se detaljer"}</Text>
            </View>
            <Image source={images.goNext} style={styles.secondViewIconStyle} />
          </View>
          <View
            style={{
              height: 1,
              backgroundColor: COLORS.white,
              marginVertical: taxiLinkFontSize(1),
            }}
          > </View>
        </TouchableOpacity>
      );
    } else {
      return (
        <View style={styles.textView}>
          <Text style={styles.textStyle}>{item.name}</Text>
          <Text style={styles.innerTextStyle}>
            {getRelativeDate(item.date)} dagar san
          </Text>
          <View
            style={{
              height: 1,
              backgroundColor: COLORS.white,
              marginVertical: taxiLinkFontSize(1),
            }}
          ></View>
        </View>
      );
    }
  };

  const renderedNonPendingItem = (item, index) => {
    console.log(item);
    return (
      <View style={styles.textView}>
        <Text style={styles.textStyle}>{item.name}</Text>
        <Text style={styles.innerTextStyle}>{item.time} dagar san</Text>
      </View>
    );
  };

  return (
    // <Modal transparent={true} visible={visible} onRequestClose={onRequestClose}>
    <View style={styles.mainContainer}>
      <View style={styles.innerContainer}>
        <View
          style={{
            width: "80%",
            alignSelf: "center",
            height: "15%",
            justifyContent: "space-evenly",
          }}
        >
          <Text style={styles.headerTxt}>{"Aviseringar"}</Text>
          {/* <Text style={styles.secondheaderTxt}>{"Ny"}</Text> */}
        </View>
        {/* SecondView */}
        <FlatList
          style={{ flex: 1, height: "80%" }}
          data={sortedData()}
          showsHorizontalScrollIndicator={false}
          extraData={sortedData()}
          renderItem={({ item, index }) => renderedItem(item, index)}
        />

        {/* <TouchableOpacity style={styles.ThirdView}>
          <View>
            <Text style={styles.thirdViewTxt}>{"Turen avklarad"}</Text>
            <Text style={styles.thirdViewTxtInner}>{"Se detaljer"}</Text>
          </View>

          <Image source={images.goNext} style={styles.thirdViewIconStyle} />
        </TouchableOpacity> */}

        {/* <View
          style={{
            width: "100%",
            paddingHorizontal: 30,
            // paddingBottom: responsiveHeight(10),
            height: "40%",
          }}
        >
          <View
            style={{
              height: "20%",
              justifyContent: "center",
            }}
          >
            <Text style={styles.FourthView}>{"Nyligen"}</Text>
          </View>

          <FlatList
            style={{ flex: 1, height: "80%" }}
            data={nonPendingBookings}
            extraData={nonPendingBookings}
            renderItem={({ item, index }) =>
              renderedNonPendingItem(item, index)
            }
          />
          <View style={styles.textView}>
            <Text style={styles.textStyle}>{"Brian Griffin"}</Text>
            <Text style={styles.innerTextStyle}>{firstPhone}</Text>
          </View>
          <View style={styles.textView}>
            <Text style={styles.textStyle}>{secondTxt}</Text>
            <Text style={styles.innerTextStyle}>{secondPhone}</Text>
          </View>
          <View style={styles.textView}>
            <Text style={styles.textStyle}>{thirdText}</Text>
            <Text style={styles.innerTextStyle}>{thirdPhone}</Text>
          </View>
          <View style={styles.textView}>
            <Text style={styles.textStyle}>{forthtext}</Text>
            <Text style={styles.innerTextStyle}>{forthphone}</Text>
          </View> 
        </View> */}
      </View>
    </View>
    // </Modal>
  );
};

export default NotificationPopUp;

const styles = StyleSheet.create({
  mainContainer: {
    height: "90%",
    width: isMobile ? responsiveWidth(80) : responsiveWidth(25),
    borderRadius: 10,
    position: "absolute",
    right: isMobile ? "10%" : "6%",
    top: isMobile ? "12%" : "10%",
    zIndex: 999999,
    backgroundColor: "black",
    borderTopLeftRadius: 20,
  },
  innerContainer: {
    height: "85%",
    backgroundColor: "black",
    alignItems: isWeb && "center",
    borderTopLeftRadius: 20,
    borderBottomLeftRadius: 30,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    width: "100%",
  },
  headerTxt: {
    alignSelf: "flex-start",
    fontFamily: fontFamilies.Inter,
    fontSize: taxiLinkFontSize(2),
    color: "#FFFFFF",
  },
  secondheaderTxt: {
    // marginTop: 20,
    fontFamily: fontFamilies.Inter,
    fontSize: taxiLinkFontSize(2),
    color: "#FFFFFF",
  },
  secondView: {
    // flexDirection: "row",
    paddingHorizontal: isWeb ? taxiLinkFontSize(2) : taxiLinkFontSize(1),
    paddingVertical: isMobile ? 10 : 10,
    width: isMobile ? "100%" : responsiveWidth(25),
    backgroundColor: "#FFCA29",
  },
  secondViewTxt: {
    color: COLORS.white,
    fontSize: isMobile ? taxiLinkFontSize(1.5) : taxiLinkFontSize(1.3),
  },
  secondViewTxtInner: {
    lineHeight: 20,
    color: COLORS.white,
    fontSize: isMobile ? taxiLinkFontSize(1.4) : taxiLinkFontSize(1),
  },
  secondViewIconStyle: {
    height: isMobile ? responsiveHeight(2) : responsiveHeight(1.8),
    width: isMobile ? responsiveWidth(2) : responsiveWidth(0.6),
    marginTop: isMobile ? 12 : 15,
  },
  ThirdView: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 30,
    paddingVertical: isMobile ? 20 : 10,
    height: "12%",
    width: isMobile ? "100%" : responsiveWidth(25),
    backgroundColor: "#FFE69A",
  },

  thirdViewTxt: {
    color: COLORS.black,
  },
  thirdViewTxtInner: {
    lineHeight: 20,
    color: COLORS.black,
    fontSize: isMobile ? taxiLinkFontSize(1.4) : taxiLinkFontSize(1),
  },
  thirdViewIconStyle: {
    height: isMobile ? responsiveHeight(2) : responsiveHeight(1.8),
    width: isMobile ? responsiveWidth(2) : responsiveWidth(0.6),
    marginTop: isMobile ? 12 : 15,
    tintColor: COLORS.black,
  },
  FourthView: {
    color: COLORS.white,
    fontFamily: fontFamilies.Inter,
    fontWeight: "800",
    fontSize: 18,
  },
  textView: {
    // height: "30%",
    width: isMobile ? "100%" : responsiveWidth(25),
    // borderBottomWidth: 0.3,
    // borderBottomColor: COLORS.white,
    justifyContent: "center",
    paddingHorizontal: isWeb ? taxiLinkFontSize(2) : taxiLinkFontSize(1),
    paddingVertical: isMobile ? 10 : 10,
  },
  textStyle: {
    color: COLORS.white,
    fontSize: isMobile ? taxiLinkFontSize(1.5) : taxiLinkFontSize(1.2),
    fontFamily: fontFamilies.Inter,
  },
  innerTextStyle: {
    color: COLORS.white,
    fontSize: isMobile ? 13 : 12,
    marginTop: isMobile ? 10 : 5,
    fontFamily: fontFamilies.Inter,
  },
});
