// export const loginLeftBackground=require('./image/loginLeftBackground.png');
export const loginLeftBackground = require('./image/loginLeftBackground.png')
export const logo = require('./image/logo.png');
export const modallogo = require('./image/Group.png');
export const cross = require('./image/Vector.png');
export const secondLogo = require('./image/secondLogo.png');
export const logo1 = require('./image/logo1.png');
export const bell = require('./image/bell.png');
export const search = require('./image/search.png');
export const logout = require('./image/logout.png');
export const eye = require('./image/eye.png');
export const clock = require('./image/clock.png');
export const location = require('./image/location.png');
export const goNext = require('./image/goNext.png');
export const mobLogoBar = require('./image/mobLogoBar.png');
export const webLogoBar = require('./image/webLogoBar.png');
export const status1 = require('./image/status1.png');
export const status2 = require('./image/status2.png');
export const status3 = require('./image/status3.png');




export default {
    loginLeftBackground,
    logo,
    modallogo,
    cross,
    secondLogo,
    logo1,
    bell,
    search,
    logout,
    eye,
    clock,
    location,
    goNext,
    mobLogoBar,
    webLogoBar,
    status1,
    status2,
    status3,
}
