import { StyleSheet, Text, View } from 'react-native'
import React, { useEffect, useState } from 'react'
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { NavigationContainer } from '@react-navigation/native';
import HomeScreen from '../screens/HomeScreen';
import Login from '../screens/Login';
import TermAndConditions from '../screens/TermAndConditions';
import AsyncStorage from '@react-native-async-storage/async-storage';

export default function Routs({ navigation }) {

    const Stack = createNativeStackNavigator();
    const [loginStatus, setLoginStatus] = useState('');

    return (
        <NavigationContainer>
            <Stack.Navigator initialRouteName='Login'>
                <Stack.Screen name="HomeScreen" component={HomeScreen} options={{ headerShown: false }} />
                <Stack.Screen name="Login" component={Login} options={{ headerShown: false }} />
                <Stack.Screen name="TermAndConditions" component={TermAndConditions} options={{ headerShown: false }} />
            </Stack.Navigator>

        </NavigationContainer>
    )
}

const styles = StyleSheet.create({})