import { initializeApp } from 'firebase/app';

// Optionally import the services that you want to use
// import {...} from "firebase/auth";
import { getDatabase, ref, set } from "firebase/database";
// import {...} from "firebase/firestore";
// import {...} from "firebase/functions";
// import {...} from "firebase/storage";

// Initialize Firebase

const firebaseConfig = {
    apiKey: "AIzaSyBYCx6Ydswi4EWNXtJLlUGpTZCNyQZzdWo",
    authDomain: "taxilink-c0f29.firebaseapp.com",
    databaseURL: "https://taxilink-c0f29-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "taxilink-c0f29",
    storageBucket: "taxilink-c0f29.appspot.com",
    messagingSenderId: "105919758477",
    appId: "1:105919758477:web:fb877113f74542bac722c4",
    measurementId: "G-HPC2X16ZF4"
};

const firbaseInitailize = initializeApp(firebaseConfig);
const db = getDatabase();

export { firbaseInitailize, db };
// For more information on how to access Firebase in your project,
// see the Firebase documentation: https://firebase.google.com/docs/web/setup#access-firebase
