import { StyleSheet, Text, View, TouchableOpacity } from "react-native";
import React from "react";
import { COLORS, isWeb, taxiLinkFontSize } from "../utils";
import { fontFamilies } from "../assets/fonts";

const CustomButton = (props) => {
  const {
    buttonStyle,
    buttonLabel,
    onPress,
    buttonInnerStyle,
    buttonTextStyle,
  } = props;
  return (
    <View style={[styles.btnContainer, buttonStyle]}>
      <TouchableOpacity
        onPress={onPress}
        style={[styles.buttonInnerContainer, buttonInnerStyle]}
      >
        <Text style={[styles.btn, buttonTextStyle]}>{buttonLabel}</Text>
      </TouchableOpacity>
    </View>
  );
};

export default CustomButton;

const styles = StyleSheet.create({
  btnContainer: {
    // flex: 0.7,
    justifyContent: "center",
    alignItems: "center",
    marginTop: isWeb ? 20 : 10,
  },
  btn: {
    fontSize: 18,
    fontFamily: fontFamilies.Inter,
    fontWeight: "700",
  },
  buttonInnerContainer: {
    width: "100%",
    height: taxiLinkFontSize(5.3),
    backgroundColor: COLORS.primary,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: taxiLinkFontSize(2)
  },
});
