import {
  StyleSheet,
  Text,
  View,
  Image,
  TextInput,
  TouchableOpacity,
  ScrollView,
  TouchableWithoutFeedback,
} from "react-native";
import React, { useState, useEffect } from "react";
import { COLORS, isMobile, isWeb, taxiLinkFontSize } from "../utils";
import { images } from "../assets";
import { fontFamilies } from "../assets/fonts";
import {
  fetchData,
  fetchId,
  updateField,
  updateReadBit,
  deleteRide,
  listenForChanges,
} from "../FirebaseMethods/AllMethods";
import CustomModal from "../components/CustomModal";
import AsyncStorage from "@react-native-async-storage/async-storage";
import NotificationPopUp from "../components/NotificationPopUp";

export default function HomeScreen({ navigation }) {
  const [search, setSearch] = useState("");
  const [dataArray, setDataArray] = useState([]);
  const [status, setStatus] = useState("new");
  const [index, setIndex] = useState("");
  const [modalData, setModalData] = useState("");
  const [count, setCount] = useState(0);
  const [allIds, setAllIds] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [secondModal, setSecondModal] = useState(false);
  const [thirdModal, setThirdModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [readedItems, setReadedItems] = useState([]);
  const [nonReadedItems, setNonReadedItems] = useState([]);
  const [wasAcceptModalOpen, setAcceptModalOpen] = useState(false);

  useEffect(() => {
    const fetch = () => {
      fetchData()
        .then((result) => {
          setDataArray(result.reverse());
          let pt = result.filter((item) => !item.readBit);
          setNonReadedItems(pt);

          let npt = result.filter((item) => item.readBit);
          setReadedItems(npt);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    };

    const fetchid = () => {
      fetchId()
        .then((result) => {
          setAllIds(result.reverse());
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    };

    const getUser = async () => {
      try {
        const savedUser = await AsyncStorage.getItem("isLoggedIn");
        const currentUser = JSON.parse(savedUser);

        console.log(currentUser);
      } catch (error) {
        console.log(error);
      }
    };
    getUser();
    fetch();
    fetchid();
    const unsubscribe = listenForChanges("booking", fetch);

    return () => {
      unsubscribe();
    };
  }, [count]);

  console.log("aaaa", dataArray);

  const updateStatusField = (value) => {
    let v = allIds[index];
    updateField(v, value);
    setTimeout(() => {
      setCount(count + 1);
    }, 1000);
  };

  const updateReadBitField = (value, item) => {
    const indexOfItem = dataArray.findIndex(
      (e) =>
        e.name === item.name && e.date === item.date && item.time === item.time
    );
    let v = allIds[indexOfItem];
    updateReadBit(v, value);
    setTimeout(() => {
      setCount(count + 1);
    }, 1000);
  };

  const removeData = async () => {
    try {
      await AsyncStorage.setItem("isLoggedIn", "");
      navigation.navigate("Login");
    } catch (error) {
      console.log(error);
    }
  };

  const cancelRide = () => {
    let id = allIds[index];
    deleteRide(id);
    setModalVisible(false);
    setCount(count + 1);
  };

  const detailModal = (item, index) => {
    updateReadBitField(true, item), setModalData(item);
    setIndex(index);
    setStatus(item.status);
    if (item.status === "pending" || item.status === "accepted") {
      setModalVisible(true);
    } else if (item.status === "complete") {
      setSecondModal(true);
    }
  };

  return (
    <TouchableOpacity
      activeOpacity={1}
      style={styles.container}
      onPress={() => setOpenModal(false)}
    >
      <View style={isWeb ? styles.webTop : styles.mobileTop}>
        <View style={styles.oneTop}>
          <TouchableOpacity onPress={() => removeData()}>
            <Image
              source={images.logout}
              style={{ width: 30, height: 30, resizeMode: "contain" }}
            />
          </TouchableOpacity>
        </View>
        <View style={styles.twoTop}>
          <View>
            <Image
              source={images.logo1}
              style={{
                width: isWeb ? taxiLinkFontSize(20) : taxiLinkFontSize(12),
                height: isWeb ? taxiLinkFontSize(10.6) : taxiLinkFontSize(7.1),
                resizeMode: "contain",
              }}
            />
          </View>
        </View>
        <View style={styles.threeTop}>
          <TouchableOpacity onPress={() => setOpenModal(!openModal)}>
            <Image
              source={images.bell}
              style={{ width: 28, height: 28, resizeMode: "contain" }}
            />
          </TouchableOpacity>
          {nonReadedItems.length > 0 && (
            <View style={styles.countingTxt}>
              <Text>{nonReadedItems.length}</Text>
            </View>
          )}
        </View>
      </View>

      <View style={isWeb ? styles.contentBoxWeb : styles.contentBoxMobile}>
        <View style={styles.innerContentOne}>
          <Text
            style={{
              fontSize: isWeb ? taxiLinkFontSize(2.8) : taxiLinkFontSize(1.8),
              fontWeight: "600",
              fontFamily: fontFamilies.InterRegualar,
              color: "black",
            }}
          >
            Bokningar
          </Text>
        </View>
        <View style={styles.innerContentTwo}>
          <>
            {isWeb ? (
              <View style={{ width: "5%" }}>
                <Text style={styles.innerTXT1}>Sr.Nej</Text>
              </View>
            ) : null}
          </>
          <View style={{ width: isWeb ? "13%" : "38%" }}>
            <Text style={[styles.innerTXT1, { textAlign: "left" }]}>
              Köparens namn
            </Text>
          </View>
          <View style={{ width: isWeb ? "13%" : "38%", alignItems: "center" }}>
            <Text style={styles.innerTXT1}>Bokningsdatum</Text>
          </View>
          <>
            {isWeb ? (
              <View style={{ width: "9%" }}>
                <Text style={styles.innerTXT1}>Bokningstid</Text>
              </View>
            ) : null}

            {isWeb ? (
              <View style={{ width: "15%", alignItems: "center" }}>
                <Text style={styles.innerTXT1}>Mötesplats</Text>
              </View>
            ) : null}
            {isWeb ? (
              <View style={{ width: "15%", alignItems: "center" }}>
                <Text style={styles.innerTXT1}>Målpunkt</Text>
              </View>
            ) : null}
          </>

          <View style={{ width: isWeb ? "8%" : "25%", alignItems: "center" }}>
            <Text style={styles.innerTXT1}>Handling</Text>
          </View>
        </View>

        <ScrollView
          showsVerticalScrollIndicator={false}
          style={styles.innerContentThree}
        >
          <View style={{ marginTop: isWeb ? "1.5%" : "4%" }}>
            {dataArray?.map((item, index) => {
              return (
                <View style={styles.innerContentOfThree}>
                  <>
                    {isWeb ? (
                      <View
                        style={{
                          width: "5%",
                          paddingLeft: isWeb ? "1%" : null,
                        }}
                      >
                        <Text style={styles.innerTXT}>{index + 1}</Text>
                      </View>
                    ) : null}
                  </>
                  <View style={{ width: isWeb ? "13%" : "38%" }}>
                    <Text numberOfLines={1} style={styles.innerTXT}>
                      {item.name}
                    </Text>
                  </View>
                  <View
                    style={{
                      width: isWeb ? "13%" : "38%",
                      alignItems: "center",
                    }}
                  >
                    <Text numberOfLines={1} style={styles.innerTXT}>
                      {item.date}
                    </Text>
                  </View>
                  <>
                    {isWeb ? (
                      <View style={{ width: "9%" }}>
                        <Text numberOfLines={1} style={styles.innerTXT}>
                          {item.time}
                        </Text>
                      </View>
                    ) : null}

                    {isWeb ? (
                      <View style={{ width: "15%" }}>
                        <Text numberOfLines={1} style={styles.innerTXT}>
                          {item.leavingFrom}
                        </Text>
                      </View>
                    ) : null}
                    {isWeb ? (
                      <View style={{ width: "15%" }}>
                        <Text numberOfLines={1} style={styles.innerTXT}>
                          {item.destination}{" "}
                        </Text>
                      </View>
                    ) : null}
                  </>

                  <View
                    style={{
                      width: isWeb ? "6%" : "25%",
                      alignItems: "center",
                    }}
                  >
                    <TouchableOpacity onPress={() => detailModal(item, index)}>
                      <Image
                        source={
                          item.status === "pending"
                            ? images.eye
                            : item.status === "accepted"
                            ? images.status1
                            : images.status3
                        }
                        style={{
                          width: taxiLinkFontSize(2),
                          height: taxiLinkFontSize(2),
                          resizeMode: "contain",
                          tintColor:
                            item.status === "pending"
                              ? COLORS.primary
                              : item.status === "accepted"
                                ? COLORS.blue
                                : COLORS.green,
                        }}
                      />
                    </TouchableOpacity>
                  </View>
                </View>
              );
            })}
            <View style={{ height: isWeb && taxiLinkFontSize(3) }}></View>
          </View>
        </ScrollView>
      </View>
      {/* FIRST MODAL*/}
      <CustomModal
        visible={modalVisible}
        ModalImage={images.modallogo}
        crossIcon={images.cross}
        modalData={modalData?.status}
        item={modalData}
        newRideButtons
        acceptRide
        acceptButtonLabel={"Complete"}
        AcceptRide
        onClose={() => {
          setModalVisible(false);
        }}
        onCancelRidePress={() => {
          cancelRide();
        }}
        onAcceptRidePress={() => {
          setModalVisible(false);
          {
            modalData.status === "pending" && updateStatusField("accepted");
          }
          setAcceptModalOpen(true);

          setThirdModal(true);
        }}
        onPress={() => {
          setModalVisible(false);
          setThirdModal(true);
        }}
        modalText={"Du har en ny Booking"}
        sixthPhone={"1 timme"}
      />

      {/* SECOND MODAL*/}
      <CustomModal
        visible={secondModal}
        ModalImage={images.secondLogo}
        crossIcon={images.cross}
        pastRide
        acceptRide
        acceptButtonLabel={"Ok"}
        onClose={() => setSecondModal(false)}
        onPress={() => setSecondModal(false)}
        modalText={"Körningen avklarad"}
        firstText={modalData?.name}
        firstPhone={"Bär Esther"}
        secondTxt={"Kundens telefonnummer"}
        secondPhone={modalData?.phoneNo}
        thirdText={"Upphämtad plats"}
        thirdPhone={modalData?.leavingFrom}
        forthtext={"Lämnade plats"}
        forthphone={modalData?.destination}
        fifthTxt={"Bokningstid"}
        fifthphone={"21:16, 31/10 2023"}
        sixthTxt={"Beräknad avlämningstid"}
        sixthPhone={"1 timme"}
        seventhTxt={"Avsatt tid"}
        seventhPhone={modalData?.time + " , " + modalData?.date}
        // eightTxt={"Ride Fare"}
        // eightPhone={"22:20, 31/10 2023"}
      />
      {/* THIRD MODAL*/}
      <CustomModal
        visible={thirdModal}
        ModalImage={images.secondLogo}
        crossIcon={images.cross}
        acceptRide
        modal18
        onClose={() => setThirdModal(false)}
        onPress={() => {
          {

            !wasAcceptModalOpen && updateStatusField("complete");
            Platform.OS==="ios"? Linking.openURL(`telprompt:${modalData?.phoneNo}`):Linking.openURL(`tel:${modalData?.phoneNo}`)
          }
          setAcceptModalOpen(false);
          setThirdModal(false);
        }}
        wasAcceptModalOpen={wasAcceptModalOpen}
        acceptButtonLabel={wasAcceptModalOpen ? "Ring kunden" : "OK"}
        modalText={wasAcceptModalOpen? "Du accepterade resan" : "Du slutförde resan"}
        firstText={"Kundens namn"}
        firstPhone={modalData?.name}
        secondTxt={"Kundens telefonnummer"}
        secondPhone={modalData?.phoneNo}
        modalContainerStyle={{
          width: isMobile ? "90%" : 400,
          height: isWeb
            ? wasAcceptModalOpen
              ? taxiLinkFontSize(10)
              : taxiLinkFontSize(22.9)
            : wasAcceptModalOpen
            ? "40%"
            : "60%",
          backgroundColor: COLORS.transparent,
          marginTop: isMobile ? "30%" : 20,
          justifyContent: "center",
        }}
        modalContentStyle={{
          backgroundColor: "black",
          flex: isWeb
            ? !wasAcceptModalOpen
              ? 0.5
              : 0.7
            : !wasAcceptModalOpen
            ? 0.7
            : 0.92,
        }}
        textViewStyle={{
          borderBottomWidth: 0,
        }}
      />

      {openModal && (
        <NotificationPopUp
          visible={openModal}
          onRequestClose={!openModal}
          readedItems={readedItems}
          nonReadedItems={nonReadedItems}
          dataArray={dataArray}
          pendingItemPress={(item, index) => {
            updateReadBitField(true, item), setModalData(item);
            setStatus(item.status);
            setIndex(index);
            setOpenModal(false);
            setModalVisible(true);
          }}
          // firstText={"Brian Griffin bokade resan"}
          // firstPhone={"5 dagar sen"}
          // secondTxt={"Brian Griffin betalade 250 SEK"}
          // secondPhone={"5 dagar sen"}
          // thirdText={"Brian Griffin bokade resan"}
          // thirdPhone={"5 dagar sen"}
          // forthtext={"Brian Griffin bokade resan"}
          // forthphone={"5 dagar sen"}
        />
      )}
      <View style={styles.bottomView}>
        <Text style={styles.bottomTXT}>©2023, TaxiLink</Text>
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: COLORS.black,
  },
  webTop: {
    width: "100%",
    height: "18%",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: COLORS.black,
    flexDirection: "row",
  },
  mobileTop: {
    width: "100%",
    height: "20%",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: COLORS.black,
    flexDirection: "row",
  },
  oneTop: {
    width: "25%",
    height: "80%",
    justifyContent: "center",
    paddingLeft: isWeb ? "5%" : "6%",
    // alignItems: 'center',
    // backgroundColor: 'green',
  },
  twoTop: {
    width: "50%",
    height: "80%",
    justifyContent: "center",
    alignItems: "center",
  },
  threeTop: {
    width: "25%",
    height: "80%",
    paddingRight: isWeb ? "5%" : "6%",
    justifyContent: "center",
    alignItems: "flex-end",
  },
  logoimgstyling: {
    resizeMode: "contain",
    width: taxiLinkFontSize(24),
    height: taxiLinkFontSize(8.6),
  },
  mainBoxOFThree: {
    width: "80%",
    height: 48,
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
  logoutbtn: {
    width: 132,
    height: "100%",
    backgroundColor: COLORS.primary,
    justifyContent: "center",

    alignItems: "center",
  },
  countingTxt: {
    width: 20,
    height: 20,
    backgroundColor: COLORS.primary,
    borderRadius: 10,
    justifyContent: "center",
    alignItems: "center",
    marginTop: isWeb ? "-5%" : "-15%",
    marginRight: isWeb ? "-2%" : "-7%",
  },
  contentBoxWeb: {
    width: "90%",
    height: "70%",
    backgroundColor: COLORS.white,
    alignSelf: "center",
    alignItems: "center",
    borderRadius: 20,
    marginTop: taxiLinkFontSize(1.5),
  },
  contentBoxMobile: {
    width: "90%",
    height: "70%",
    backgroundColor: COLORS.white,
    alignSelf: "center",
    alignItems: "center",
    borderRadius: 20,
  },
  innerContentOne: {
    width: isWeb ? "95%" : "90%",
    height: "12%",
    // backgroundColor: 'red',
    justifyContent: "center",
    marginTop: isWeb ? taxiLinkFontSize(2) : taxiLinkFontSize(0),
  },
  innerContentTwo: {
    width: isWeb ? "95%" : "90%",
    backgroundColor: isWeb ? COLORS.black : null,
    height: "8%",
    paddingHorizontal: isWeb && 5,
    borderBottomWidth: isWeb ? null : 0.3,
    borderBottomColor: "#A8B0B9",
    justifyContent: isWeb ? "space-between" : "space-evenly",
    alignItems: "center",
    flexDirection: "row",
    borderRadius: isWeb && taxiLinkFontSize(0.5),
    paddingLeft: isWeb && taxiLinkFontSize(2),
  },
  innerContentOfThree: {
    width: isWeb ? "100%" : "100%",
    // backgroundColor: isWeb ? COLORS.black : null,
    height: "8%",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    marginTop: isWeb ? taxiLinkFontSize(2.2) : "7%",
  },
  innerContentThree: {
    width: isWeb ? "95%" : "90%",
    height: isWeb ? taxiLinkFontSize(60) : taxiLinkFontSize(50),
    paddingLeft: isWeb && taxiLinkFontSize(2),
    paddingRight: isWeb && taxiLinkFontSize(1),
  },

  innerTXT: {
    fontSize: isWeb ? taxiLinkFontSize(1.4) : taxiLinkFontSize(1.1),
    fontWeight: "400",
    fontFamily: fontFamilies.Inter,
    color: COLORS.lightGray,
  },

  innerTXT1: {
    fontSize: isWeb ? taxiLinkFontSize(1.6) : taxiLinkFontSize(1.4),
    fontWeight: "400",
    fontFamily: fontFamilies.Inter,
    color: isWeb ? COLORS.white : COLORS.lightGray,
  },
  bottomView: {
    width: "100%",
    height: "9%",
    position: "absolute",
    bottom: 0,
    justifyContent: "center",
    alignItems: "center",
  },
  bottomTXT: {
    color: COLORS.white,
    fontFamily: fontFamilies.Inter,
    fontSize: isWeb ? taxiLinkFontSize(2.4) : taxiLinkFontSize(1.8),
    fontWeight: "400",
  },
});
