import {
  View,
  Text,
  Modal,
  StyleSheet,
  TouchableOpacity,
  Image,
  ScrollView,
} from "react-native";
import React from "react";
import { images } from "../assets";
import {
  responsiveHeight,
  responsiveWidth,
  taxiLinkFontSize,
} from "../utils/responsiveDimensions";
import { fontFamilies } from "../assets/fonts";
import { COLORS, isMobile, isWeb } from "../utils";
import CustomButton from "./CustomButton";

const CustomModal = (props) => {
  const {
    visible,
    onClose,
    modalText,
    ModalImage,
    crossIcon,
    imgstyle,
    firstText,
    firstPhone,
    secondPhone,
    secondTxt,
    thirdText,
    thirdPhone,
    forthtext,
    forthphone,
    fifthTxt,
    fifthphone,
    sixthTxt,
    sixthPhone,
    seventhTxt,
    seventhPhone,
    eightTxt,
    eightPhone,
    newRideButtons,
    acceptRide,
    acceptRideButtonStyle,
    acceptButtonLabel,
    pastRide,
    modal18,
    onPress,
    onAcceptRidePress,
    onCancelRidePress,
    AcceptRide,
    modalData,
    modalContainerStyle,
    modalContentStyle,
    textViewStyle,
    item,
    wasAcceptModalOpen,
  } = props;
  console.log("Modal Data -->>", modalText);

  return (
    <Modal
      // animationType="slide"
      transparent={true}
      visible={visible}
    >
      <View style={[styles.modalContainer, modalContainerStyle]}>
        <View style={[styles.modalContent, modalContentStyle]}>
          <TouchableOpacity style={styles.crossIconContainer} onPress={onClose}>
            <Image source={crossIcon} style={styles.crossDesign} />
          </TouchableOpacity>
          <View style={styles.modalHeaderDesign}>
            <Image
              source={ModalImage}
              style={[styles.modalImageDesign, imgstyle]}
            />
            <Text style={styles.modalTextdesign}>{modalText}</Text>
          </View>
          <ScrollView
            showsVerticalScrollIndicator={false}
            style={{ width: "100%" }}
          >
            <View style={styles.scrollViewDesign}>
              {AcceptRide && (
                <>
                  <View style={styles.textView}>
                    <Text style={styles.modalDetailText}>Köparens namn</Text>
                    <Text
                      style={[
                        styles.modalDetailText,
                        { marginTop: taxiLinkFontSize(1) },
                      ]}
                    >
                      {item.name}
                    </Text>
                  </View>
                  <View style={styles.textView}>
                    <Text style={styles.modalDetailText}>
                      Kundens telefonnummer
                    </Text>
                    <Text
                      style={[
                        styles.modalDetailText,
                        { marginTop: taxiLinkFontSize(1) },
                      ]}
                    >
                      {item.phoneNo}
                    </Text>
                  </View>
                  <View style={styles.textView}>
                    <Text style={styles.modalDetailText}>
                      Upphämtningsplats
                    </Text>
                    <Text
                      style={[
                        styles.modalDetailText,
                        { marginTop: taxiLinkFontSize(1) },
                      ]}
                    >
                      {item.leavingFrom}
                    </Text>
                  </View>
                  <View style={styles.textView}>
                    <Text style={styles.modalDetailText}>Avlämningsplats</Text>
                    <Text
                      style={[
                        styles.modalDetailText,
                        { marginTop: taxiLinkFontSize(1) },
                      ]}
                    >
                      {item.destination}
                    </Text>
                  </View>
                  <View style={styles.textView}>
                    <Text style={styles.modalDetailText}>Bokningstid</Text>
                    <Text
                      style={[
                        styles.modalDetailText,
                        { marginTop: taxiLinkFontSize(1) },
                      ]}
                    >
                      {item.time}, {item.data}
                    </Text>
                  </View>
                  <View style={styles.textView}>
                    <Text style={styles.modalDetailText}>
                      Beräknad avlämningstid
                    </Text>
                    <Text
                      style={[
                        styles.modalDetailText,
                        { marginTop: taxiLinkFontSize(1) },
                      ]}
                    >
                      {sixthPhone}
                    </Text>
                  </View>
                </>
              )}
              {modal18 && (
                <>
                  {wasAcceptModalOpen && (
                    <>
                      <View style={[styles.textView, textViewStyle]}>
                        <Text style={styles.modalDetailText}>{firstText}</Text>
                        <Text
                          style={[
                            styles.modalDetailText,
                            { marginTop: taxiLinkFontSize(1) },
                          ]}
                        >
                          {firstPhone}
                        </Text>
                      </View>
                      <View style={[styles.textView, textViewStyle]}>
                        <Text style={styles.modalDetailText}>{secondTxt}</Text>
                        <Text
                          style={[
                            styles.modalDetailText,
                            { marginTop: taxiLinkFontSize(1) },
                          ]}
                        >
                          {secondPhone}
                        </Text>
                      </View>
                    </>
                  )}
                </>
              )}
              {pastRide && (
                <>
                  <View style={styles.textView}>
                    <Text style={styles.modalDetailText}>{firstText}</Text>
                    <Text
                      style={[
                        styles.modalDetailText,
                        { marginTop: taxiLinkFontSize(1) },
                      ]}
                    >
                      {firstPhone}
                    </Text>
                  </View>
                  <View style={styles.textView}>
                    <Text style={styles.modalDetailText}>{secondTxt}</Text>
                    <Text
                      style={[
                        styles.modalDetailText,
                        { marginTop: taxiLinkFontSize(1) },
                      ]}
                    >
                      {secondPhone}
                    </Text>
                  </View>
                  <View style={styles.textView}>
                    <Text style={styles.modalDetailText}>{thirdText}</Text>
                    <Text
                      style={[
                        styles.modalDetailText,
                        { marginTop: taxiLinkFontSize(1) },
                      ]}
                    >
                      {thirdPhone}
                    </Text>
                  </View>
                  <View style={styles.textView}>
                    <Text style={styles.modalDetailText}>{forthtext}</Text>
                    <Text
                      style={[
                        styles.modalDetailText,
                        { marginTop: taxiLinkFontSize(1) },
                      ]}
                    >
                      {forthphone}
                    </Text>
                  </View>
                  <View style={styles.textView}>
                    <Text style={styles.modalDetailText}>{fifthTxt}</Text>
                    <Text
                      style={[
                        styles.modalDetailText,
                        { marginTop: taxiLinkFontSize(1) },
                      ]}
                    >
                      {fifthphone}
                    </Text>
                  </View>
                  <View style={styles.textView}>
                    <Text style={styles.modalDetailText}>{sixthTxt}</Text>
                    <Text
                      style={[
                        styles.modalDetailText,
                        { marginTop: taxiLinkFontSize(1) },
                      ]}
                    >
                      {sixthPhone}
                    </Text>
                  </View>
                  <View style={styles.textView}>
                    <Text style={styles.modalDetailText}>{seventhTxt}</Text>
                    <Text
                      style={[
                        styles.modalDetailText,
                        { marginTop: taxiLinkFontSize(1) },
                      ]}
                    >
                      {seventhPhone}
                    </Text>
                  </View>
                  {/* {eightPhone !=="" &&<View style={styles.textView}>
                    <Text style={styles.modalDetailText}>{eightTxt}</Text>
                    <Text
                      style={[
                        styles.modalDetailText,
                        { marginTop: taxiLinkFontSize(1) },
                      ]}
                    >
                      {eightPhone}
                    </Text>
                  </View>} */}
                </>
              )}
            </View>
            {modalData === "pending" ? (
              <>
                {newRideButtons && (
                  <View style={styles.buttonView}>
                    <CustomButton
                      onPress={onAcceptRidePress}
                      buttonInnerStyle={{
                        width: taxiLinkFontSize(15),
                        height: taxiLinkFontSize(4.8),
                        borderRadius: taxiLinkFontSize(0.1),
                        // paddingHorizontal: taxiLinkFontSize(2),
                      }}
                      buttonLabel={"Acceptera resan"}
                      buttonTextStyle={{ fontSize: 16 }}
                    />
                    <CustomButton
                    onPress={onCancelRidePress}
                      buttonInnerStyle={{
                        width: taxiLinkFontSize(15),
                        height: taxiLinkFontSize(4.8),
                        borderRadius: taxiLinkFontSize(0.1),
                        // paddingHorizontal: taxiLinkFontSize(2),
                        backgroundColor: "#F04438",
                      }}
                      buttonLabel={"Neka resa"}
                      buttonTextStyle={{ color: COLORS.white, fontSize: 16 }}
                    />
                  </View>
                )}
              </>
            ) : (
              <>
                {acceptRide && (
                  <View
                    style={[
                      styles.buttonView,
                      acceptRideButtonStyle,
                      { justifyContent: "center" },
                    ]}
                  >
                    <CustomButton
                      onPress={onPress}
                      buttonInnerStyle={{
                        width: "100%",
                        height: taxiLinkFontSize(4.8),
                        borderRadius: taxiLinkFontSize(0.1),
                        paddingHorizontal:
                          acceptButtonLabel === "Ring kunden"
                            ? taxiLinkFontSize(4)
                            : taxiLinkFontSize(12),
                        marginTop: wasAcceptModalOpen && taxiLinkFontSize(2),
                      }}
                      buttonLabel={acceptButtonLabel}
                      buttonTextStyle={{ fontSize: taxiLinkFontSize(1.6) }}
                    />
                  </View>
                )}
              </>
            )}
          </ScrollView>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    width: isMobile ? "90%" : taxiLinkFontSize(40),
    alignSelf: "center",
    marginBottom: isMobile ? 25 : 0,
    backgroundColor: "#000000",
    borderRadius: 10,
    height: isMobile ? "100%" : "45%",
    marginTop: isMobile ? 10 : 5,
  },
  modalContent: {
    flex: 1,
    // height:'100%',
    backgroundColor: COLORS.black,
    alignItems: "center",
    borderWidth: 0.1,
  },
  modalImageDesign: {
    marginBottom: isMobile ? 0 : 5,
    height: isMobile ? taxiLinkFontSize(7.1) : taxiLinkFontSize(7.1),
    width: isMobile ? taxiLinkFontSize(7.1) : taxiLinkFontSize(7.1),
    resizeMode: "contain",
  },
  modalTextdesign: {
    color: "white",
    fontFamily: fontFamilies.Inter,
    fontWeight: "700",
    fontSize: isMobile ? taxiLinkFontSize(2) : taxiLinkFontSize(2.4),
    textAlign: "center",
    marginTop: isMobile ? responsiveHeight(3.5) : responsiveHeight(2),
  },
  crossDesign: {
    height: isMobile ? responsiveHeight(1.5) : responsiveHeight(2),
    width: isMobile ? responsiveWidth(3) : responsiveWidth(1),
  },
  crossIconContainer: {
    marginLeft: isWeb ? 50 : 0,
    width: isWeb ? 40 : 50,
    height: isWeb ? 40 : 50,
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "flex-end",
    marginRight: isWeb ? 15 : 0,
    marginTop: taxiLinkFontSize(1.5),
  },
  modalHeaderDesign: {
    width: "100%",
    alignSelf: "center",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: isMobile ? 30 : 20,
  },
  scrollViewDesign: {
    flex: 1,
    backgroundColor: "black",
    width: "100%",
  },
  textView: {
    width: "100%",
    height: isMobile ? responsiveHeight(10) : responsiveHeight(10),
    justifyContent: "center",
    alignItems: "center",
    borderBottomWidth: 0.2,
    borderBottomColor: COLORS.darkgray,
  },
  textStyle: {
    color: COLORS.white,
    fontSize: isMobile ? 18 : 10,
    fontFamily: fontFamilies.Inter,
  },
  innerTextStyle: {
    color: COLORS.white,
    fontSize: isMobile ? 18 : 12,

    // fontSize: 18,
    marginTop: isMobile ? 10 : 5,
    fontFamily: fontFamilies.Inter,
  },
  buttonView: {
    flexDirection: "row",
    width: isMobile ? "90%" : "79%",
    justifyContent: "space-between",
    alignSelf: "center",
    marginVertical: isMobile ? 30 : 15,
  },
  modalDetailText: {
    color: COLORS.white,
    textAlign: "center",
    fontFamily: fontFamilies.InterRegular,
    fontSize: taxiLinkFontSize(1.6),
    //fontWeight: "400",
    lineHeight: isWeb && taxiLinkFontSize(2),
  },
});

export default CustomModal;
