import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View } from 'react-native';
import Routs from './src/navigation/Routs';
import useCachedResources from './src/utils/useCachedResources'

export default function App() {
  const isLoadingComplete = useCachedResources();
  if (!isLoadingComplete) return null
  else {
    return (
      <View style={styles.container}>
        <Routs />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
