import { StyleSheet, Text, View, TextInput } from "react-native";
import React from "react";
import { taxiLinkFontSize, isWeb, COLORS } from "../utils";
import { fontFamilies } from "../assets/fonts";
import { mobileHeight } from "../utils/Sizes";

const CustomTextInput = (props) => {
  const {
    onChangeText,
    value,
    inputlable,
    placeholder,
    placeholderTextColor,
    inputlableStyle,
    secureTextEntry,
  } = props;
  return (
    <View style={isWeb ? styles.inputBoxweb : styles.inputBoxMobile}>
      <Text style={[styles.inputLabel, inputlableStyle]}>{inputlable}</Text>
      <TextInput
        style={styles.inputweb}
        onChangeText={onChangeText}
        placeholder={placeholder}
        placeholderTextColor={placeholderTextColor}
        value={value}
        secureTextEntry={secureTextEntry}
      />
    </View>
  );
};

export default CustomTextInput;

const styles = StyleSheet.create({
  inputBoxMobile: {
    width: "100%",
    // height: "41%",
    // marginTop:5,

    // backgroundColor:'red',
    marginTop: 20,
  },
  inputBoxweb: {
    // width: "100%",
    // height: "33%",
    // justifyContent: "space-between",
    marginTop:mobileHeight<750? 20:10,
  },
  inputweb: {
    width: "100%",
    height: 57,
    borderWidth: 0.5,
    borderColor: COLORS.white,
    borderRadius: 20,
    paddingHorizontal: 20,
    color: COLORS.white,
    fontSize: isWeb ? taxiLinkFontSize(1.6) : taxiLinkFontSize(1.3),
  },
  inputmobile: {},
  inputLabel: {
    fontSize: isWeb ? taxiLinkFontSize(2.1) : taxiLinkFontSize(1.8),
    color: COLORS.white,
    fontFamily: fontFamilies.InterRegular,
    marginVertical: 10,
  },
});
