import {
    StyleSheet,
    Text,
    View,
    Image,
    StatusBar,
    Platform,
    TouchableOpacity,
    Dimensions,
  } from "react-native";
  import React from "react";
  import { images } from "../assets";
  import { COLORS, SIZES } from "../utils";
  import { isMobile, isWeb } from "../utils/Sizes";
  
  const width = Dimensions.get("screen").width;

  export default function HeaderForTermAndCondition(props) {
    return (
      <View style={styles.container}>
        <View style={styles.one}>
          <View style={styles.imgBox}>
            <Image
              source={images.location}
              style={
                isMobile ? styles.mobLocationImg : styles.webLocationImg
              }
            />
          </View>
          <Text
            style={isMobile ? styles.mobileLocation : styles.webLocation}
          >
            12232 Kalmar Sweedan
          </Text>
        </View>
        <View style={styles.one1}>
          {isWeb ? (
            <TouchableOpacity style={styles.driverLogin}>
              <Text>Driver Login</Text>
            </TouchableOpacity>
          ) : null}
  
          <View style={styles.imgBox}>
            <Image
              source={images.clock}
              style={isMobile ? styles.mobTimeImg : styles.webTimeImg}
            />
          </View>
          <Text style={{ color: "white", fontSize: isWeb? 14 : 12}}>24/24 - 7/7</Text>
        </View>
      </View>
    );
  }
  
  const styles = StyleSheet.create({
    container: {
      width: "100%",
      height: isWeb? 70: 50,
      // ...Platform.select({android:{backgroundColor:'black',}}),
      //backgroundColor:'black',
      flexDirection: "row",
      alignItems: "center",
      paddingHorizontal: isWeb ? 30 : 15,
      justifyContent: "space-between",
      backgroundColor: COLORS.transparent
    },
    one: {
      flexDirection: "row",
      // ...Platform.select({ web: { width: "15%" }, android: { width: "40%" } }),
  
      height: 40,
      alignItems: "center",
      justifyContent: "space-between",
    },
  
    one1: {
      flexDirection: "row",
      // ...Platform.select({ web: { width: "25%" }, android: { width: "40%" } }),
  
      // height: 40,
      alignItems: "center",
      justifyContent: "space-between",
    },
  
    imgBox: {
      alignItems: "center",
      justifyContent: "center",
    },
    driverLogin: {
      width: 120,
      height: 25,
      backgroundColor: "#ffffff",
      justifyContent: "center",
      alignItems: "center",
    },
    webLocation: {
      color: COLORS.white,
      fontSize: 14,
      fontWeight: "600",
      marginLeft: 15,
    },
    mobileLocation: {
      color: COLORS.white,
      fontSize: 12,
      fontWeight: "400",
      marginLeft: 10,
    },
    mobLocationImg: {
      width: 12,
      height: 18,
      resizeMode: "contain",
    },
    webLocationImg: {
      width: 17,
      height: 28,
      resizeMode: "contain",
    },
    mobTimeImg: {
      width: 17,
      height: 17,
      resizeMode: "contain",
      marginLeft: 15,
      marginRight: 15,
    },
    webTimeImg: {
      width: 25,
      height: 25,
      resizeMode: "contain",
      marginLeft: 25,
      marginRight: 10,
    },
  });
  