
export const COLORS = {
    // base colors
    primary: "#FFCA29",
    secondary: "#FFFFFF", 


    // colors
    black: "#1F1F1F",
    white: "#FFFFFF",
    red:'#ED1C24',
    lightGray: "#4F5B67",
    lightGray2: "#2D2D2D",
    lightGray3: "#EFEFF1",
    lightGray4: "#F8F8F9",
    transparent: "transparent",
    darkgray: '#898C95',
    navyBlue:'#060612',
    blackBg: '#151515',
    blue: '#0000B9',
    green: '#006A4E'
};
